import AboutModal from "./AboutModal.json";
import Buttons from "./Buttons.json";
import CineDialog from "./CineDialog.json";
import Common from "./Common.json";
import DownloadDialog from "./DownloadDialog.json";
import DatePicker from "./DatePicker.json";
import Header from "./Header.json";
import UserPreferencesModal from "./UserPreferencesModal.json";

export default {
  "pt-BR": {
    AboutModal,
    Buttons,
    CineDialog,
    Common,
    DownloadDialog,
    DatePicker,
    Header,
    UserPreferencesModal
  }
};
