//import OHIFLogo from '../components/OHIFLogo/OHIFLogo.js';
import RdicomLogo from '../components/RdicomLogo/RdicomLogo.js';
import React from 'react';

const defaultContextValues = {
  logoComponent: RdicomLogo(),
};

const WhiteLabellingContext = React.createContext(defaultContextValues);

export default WhiteLabellingContext;
