// Commenting this out for now since it looks like Rollup is pulling in the
// Node.js version instead of the Browser version of this package
//import { btoa } from 'isomorphic-base64';
import user from '../user';
import axios from 'axios';

/**
 * Returns the Authorization header as part of an Object.
 *
 * @export
 * @param {Object} [server={}]
 * @param {Object} [server.requestOptions]
 * @param {string|function} [server.requestOptions.auth]
 * @returns {Object} { Authorization }
 */
export default function getAuthorizationHeader({ requestOptions } = {}) {
  const headers = {};

  // Check for OHIF.user since this can also be run on the server
  //const accessToken = user && user.getAccessToken && user.getAccessToken();

  const accessToken = localStorage.authToken;

  //console.log('tokennnnnnnnnn: ' + accessToken);

  //headers.Authorization = "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJqV1FmM3gxV1ptYXBodkxyMVdQbTVLaVVydmY3TDN3OGNSQlhWbW9lN0NVIn0.eyJqdGkiOiJhNzkyY2MwZS03MzIzLTQ0ODMtYTVkYi0zMzRjOTQ4NmVhYmIiLCJleHAiOjE1NzI0NjQ5MDEsIm5iZiI6MCwiaWF0IjoxNTcyNDYzMTAxLCJpc3MiOiJodHRwczovLzM1LjE5OS43My4yMDY6ODg0My9hdXRoL3JlYWxtcy9yc2VydmVyIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjI2YmNlYWU4LTY0ZGYtNDE4My05N2MzLWJlNDg4MTI1MGI0ZSIsInR5cCI6IkJlYXJlciIsImF6cCI6InJzZXJ2ZXIiLCJhdXRoX3RpbWUiOjAsInNlc3Npb25fc3RhdGUiOiJjMWZlNTRkMi01NzAxLTQyMmUtYTgwOC03YmEzYmYwNWQ0OTAiLCJhY3IiOiIxIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJ1c2VyIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJlbWFpbCBwcm9maWxlIiwiY2xpZW50SG9zdCI6IjE3OS4xNTQuMTUwLjc5IiwiY2xpZW50SWQiOiJyc2VydmVyIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzZXJ2aWNlLWFjY291bnQtcnNlcnZlciIsImNsaWVudEFkZHJlc3MiOiIxNzkuMTU0LjE1MC43OSIsImVtYWlsIjoic2VydmljZS1hY2NvdW50LXJzZXJ2ZXJAcGxhY2Vob2xkZXIub3JnIn0.PsupaPLrOY1r5dLPQtAEEqp6Yq5W-gWi6DX7nVafV0DtUT5cJrdHVjz6TIjR0nBCvqO9QRbQVpdQTVtrdibNUjSjA9LcsKROo3iT_tBce1s01d_pnZ83Fsw2nQSBN6xfOEagcVdm8Ido1crX0aoENdDHmA1irMb1MOg6DMrQRyZC8Q9xdudwNKWgWnmWjQzmpybdYNcEHq8CQDqQlzKe9zy-dhP8-xlL7lAxWuYcTdW6spfcFBs3nSuhuvyr4MoyBgmHYKWhUddvHgTOd_QLlFd-lOJO0gdB685YrA8RRYOVdb5dldOatXkHkh5ESj6bX60OmAtof9NQxSCyq7L_bg";

  if (requestOptions && requestOptions.auth) {
    if (typeof requestOptions.auth === 'function') {
      // Custom Auth Header
      //console.log("entrei aquiiiiiiiiiiiiiii");
      headers.Authorization = requestOptions.auth(requestOptions);
    } else {
      // HTTP Basic Auth (user:password)
      //console.log("entrei acola");
      headers.Authorization = `Basic ${btoa(requestOptions.auth)}`;
    }
  } else if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return headers;
}

function getAccessToken() {
  const URL_Auth =
    'https://35.199.73.206:8843/auth/realms/rserver/protocol/openid-connect/token';

  /*const data = {
    "grant_type": "client_credentials",
    "scope": "public",
    "client_id": "rserver",
    "client_secret": "eb637f44-94bc-4da8-a310-b45924adb19d"
  };*/

  /*   const data = "grant_type=client_credentials&scope=public&client_id=rserver&client_secret=eb637f44-94bc-4da8-a310-b45924adb19d";

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const { data } =  axios.post(URL_Auth, data, config)

    console.log('Bearer ' + JSON.stringify(data));
    console.log('Bearer ' + data.access_token); */

  /* axios.post(URL_Auth, data, config).then(response => {
    console.log('Bearer ' + JSON.stringify(response));
    console.log('Bearer ' + response.data.access_token);
    return response.data.access_token;
  }).catch(error => {
    console.log(error);
  })
 */
}
